import axios from 'axios';
import { apiUrl} from'../Config/Config';

const mem_plan = () => {
    let planLists = [];
    axios.post(apiUrl+"membership/plantypes").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let planlist = res.data.planlist;
            
            for(i; i<planlist.length; i++){
                planLists[i] = { value: planlist[i]['mem_cat_id'], label: planlist[i]['category'] };
            }
            
        }       
    });
    return planLists;
}

// const domainList = () => {
//     let domainlist = [];
//     axios.post(apiUrl+"user/domainlist").then(res => {
//         if(res.data.status == "success"){
//             let i=0;
//             let domainlists = res.data.domainlist;
            
//             for(i; i<domainlists.length; i++){
//                 domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
//             }
            
//         }       
//     });
   
//     return domainlist;
// }

const categories = () => {
    var qs = require('qs');
    var postobject = {};

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const quizcategories_main = () => {
    var qs = require('qs');
    var postobject = {
        type: 'main'
    };

    let catelists = [];
    axios.post(apiUrl+"quizcategory/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const quizcategories_all = () => {
    var qs = require('qs');
    
    var postobject = {
        type: 'all'
    };

    let catelists = [];
    axios.post(apiUrl+"quizcategory/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const quizcategories_withall = () => {
    var qs = require('qs');
    
    var postobject = {
        type: 'with_all'
    };

    let catelists = [];
    axios.post(apiUrl+"quizcategory/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const categories1 = () => {
    var qs = require('qs');

    var postobject = {
        type: 'all'
    };

    let catelists = [];
    axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let catelist = res.data.catelist;
            
            for(i; i<catelist.length; i++){
                catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
            }
            
        }       
    });
    return catelists;
}

const institute = () => {
    var qs = require('qs');

    var postobject = {};

    let inslists = [];
    axios.post(apiUrl+"institute/inslist",qs.stringify(postobject)).then(res => {
        if(res.data.status == "success"){
            let i=0;
            let institutelist = res.data.catelist;
            
            for(i; i<institutelist.length; i++){
                inslists[i] = { value: institutelist[i]['id'], label: institutelist[i]['institute'] };
            }
            
        }       
    });
    return inslists;
}

const subjectslist = () => {
    var qs = require('qs');
    var postobject = {};
    
    let sublists = [];
    axios.post(apiUrl+"sessionmanagement/subjectslist",qs.stringify(postobject)).then(res => {        
        if(res.data.status === "success"){
            let i=0;
            let sublist = res.data.sublist;
            
            for(i; i<sublist.length; i++){
                sublists[i] = { value: sublist[i]['id'], label: sublist[i]['subject_name'] };
            }
            
        }       
    });
    return sublists;
}

const tutorslist = () => {
    var qs = require('qs');
    var postobject = {};
    
    let tutorlists = [];
    axios.post(apiUrl+"tutor/tutorslist",qs.stringify(postobject)).then(res => {        
        if(res.data.status === "success"){
            let i=0;
            let tutorlist = res.data.tutorlist;
            
            for(i; i<tutorlist.length; i++){
                tutorlists[i] = { value: tutorlist[i]['id'], label: tutorlist[i]['name'] };
            }
            
        }       
    });
    return tutorlists;
}

const studentslist = () => {
    var qs = require('qs');
    var postobject = {};
    
    let studentlists = [];
    axios.post(apiUrl+"student/studentslist",qs.stringify(postobject)).then(res => {        
        if(res.data.status === "success"){
            let i=0;
            let studentlist = res.data.studentlist;           
            
            for(i; i<studentlist.length; i++){
                let lastname = "";
                if(studentlist[i]['lastname'] != "" && studentlist[i]['lastname'] != null){
                    lastname = studentlist[i]['lastname'];
                }
                let sname = studentlist[i]['firstname'] + ' ' + lastname;
                studentlists[i] = { value: studentlist[i]['id'], label: sname };
            }
            
        }       
    });
    return studentlists;
}

const classlist = () => {
    var qs = require('qs');
    var postobject = {};
    
    let classlists = [];
    axios.post(apiUrl+"classes/classeslist",qs.stringify(postobject)).then(res => {        
        if(res.data.status === "success"){
            let i=0;
            let classlist = res.data.classlist;           
            
            for(i; i<classlist.length; i++){
                
                let sname = classlist[i]['name'];
                classlists[i] = { value: classlist[i]['id'], label: sname };
            }
            
        }       
    });
    return classlists;
}

export const lang = {

    common: {
        title:'QA AI',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        status_option1:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
            { value: 'blocked', label: 'Blocked' },
        ],
        type_option:[
            { value: 'Institute', label: 'Institute' },
            { value: 'Student', label: 'Student' },
        ],
        categories_option: categories(),
        quizcategories_main_option: quizcategories_main(),
        quizcategories_all_option: quizcategories_all(),
        quizcategories_withall_option: quizcategories_withall(),
        categories_option1: categories1(),
        subjects_option: subjectslist(),
        tutors_option: tutorslist(),
        students_option: studentslist(),
        class_option: classlist(),
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' }
        ],
        quiz_mode_option: [
            { value: 'Test', label: 'Test' },
            { value: 'Tutor', label: 'Tutor' }
        ],
        tab_module_option: [
            { value: 'Subscription', label: 'Subscription' },
            { value: 'Quiz', label: 'Quiz' },
            { value: 'tutor', label: 'Tutor' },
            { value: 'store', label: 'Store' },
            { value: 'other', label: 'Others' },
        ],
        advertisement_linktype:[
            { value: 'external', label: 'External link' },
            { value: 'product', label: 'Product link' },
            { value: 'internal', label: 'Internal link' },
        ],
        advertisement_internallinks:[
            { value: 'tutor', label: 'Tutor Tab' },
            { value: 'subscription', label: 'Subscription Tab' },
            { value: 'test', label: 'Test Mode' },
            { value: 'store', label: 'Store' },
        ],
        institute_option: institute(),
        examtype_option: [
            { value: 'Quarterly', label: 'Quarterly' },
            { value: 'Half yearly', label: 'Half yearly' },
            { value: 'Annually', label: 'Annually' },
            { value: 'Cycle Test', label: 'Cycle Test' }
        ],
		/* image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],*/
        image_size_option: [
            { value: '1031x400', label: '1031x400 (W X H)' },
            { value: '250x180', label: '250x180 (W X H)' },
			{ value: '200x150', label: '200x150 (W X H)' },
            { value: '180x120', label: '180x120 (W X H)' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
        field_options :[
            { value: 'institute', label: 'institute' },
            { value: 'tutor', label: 'tutor' },
            { value: 'student', label: 'student' },
            { value: 'session', label: 'session' },
            { value: 'sessionreport', label: 'sessionreport' },
            { value: 'orders', label: 'orders' },
            { value: 'package', label: 'package' },
            { value: 'Update Membership', label: 'update_membership' },
            { value: 'categories', label: 'categories' },
            { value: 'mysession', label: 'mysession' },
            { value: 'completed_session', label: 'completed_session' },
            { value: 'my_report', label: 'my_report' },
            { value: 'store', label: 'store' },
            { value: 'students', label: 'students' },
            { value: 'classes', label: 'classes' },
            { value: 'questions', label: 'questions' },
            { value: 'session-reports', label: 'session-reports' },
            { value: 'subscription', label: 'subscription' },
            { value: 'tutors', label: 'tutors' },
            { value: 'sessions', label: 'sessions' },
            { value: 'yearly', label: 'yearly' },
            { value: 'monthly', label: 'monthly' },
        ],
        discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'standard', label: 'Standard' },
        ],
        login_type:[
            { value: 'student', label: 'Student' },
            { value: 'tutor', label: 'Tutor' },
            { value: 'institute', label: 'Institute' },
            { value: 'company', label: 'Company' },
        ],
        module_option:[
            { value: 'module1', label: 'Module1 (Doctor Listing)' },
            { value: 'module2', label: 'Module2 (Product Listing)' },
        ],
        productType_option:[
            { value: '1', label: 'Normal Product' },
            { value: '2', label: 'Shopping Product' },
            { value: '3', label: 'Regular Product' },
        ],
        period_option: [
            // { value: 'Day', label: 'Day' },
            { value: 'Month', label: 'Month' },
            { value: 'Year', label: 'Year' }
        ],
        // domainList_option:domainList(),
        mem_plan_option: mem_plan(),

    },
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
};


