/*local */ 
// export const apiUrl      = "http://localhost/qa/api/";
// export const baseUrl      = "http://localhost/qa";
// export const mediaUrl      = "http://localhost/qa/media/";
// export const tabUrl      = "http://localhost/qa/media/tabs";
// export const adUrl      = "http://localhost/qa/media/advertisements";
// export const sampleFileUrl      = "http://localhost/qa/media/sample_file/";
// export const cmscontentURL       = "http://localhost/qa/media/cmscontent";
// export const productURL       = "http://localhost/qa/media/product";
// export const mediaUrlImage      = "http://localhost/qa/media/product";

/*Live */
export const apiUrl      = "https://admin.shal.one/qa/api/";
export const productURL       = "https://admin.shal.one/qa/media/product";
export const baseUrl      = "https://admin.shal.one/qa";
export const mediaUrl      = "https://admin.shal.one/qa/media/";
export const tabUrl      = "https://admin.shal.one/qa/media/tabs";
export const adUrl      = "https://admin.shal.one/qa/media/advertisements";
export const sampleFileUrl      = "https://admin.shal.one/qa/media/sample_file/";
export const cmscontentURL       = "https://admin.shal.one/qa/media/cmscontent";    
export const mediaUrlImage      = "https://admin.shal.one/qa/media/product";