import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';



import './common/css/font-awesome.min.css';

import Adminprofile from './components/Account/Adminprofile';
import Login from './components/Account/Login';
import Logout from "./components/Account/Logout";
import Setting from "./components/Account/Setting";
import Changepassword from "./components/Account/Changepassword";
import Forgetpassword from "./components/Account/Forgetpassword";
import Resetpassword from "./components/Account/Resetpassword";
import Company from "./components/Company/List";
import CompanyAdd from "./components/Company/Add"
import CompanyEdit from "./components/Company/Edit";

import StudentList from "./components/Student/List";
import StudentEdit from "./components/Student/Edit";
import StudentAdd from "./components/Student/Add";
import StudentView from "./components/Student/View";

import BlockedstudentList from "./components/Blockedstudent/List";
import BlockedstudentEdit from "./components/Blockedstudent/Edit";

import TutorList from "./components/Tutor/List";
import TutorEdit from "./components/Tutor/Edit";
import TutorAdd from "./components/Tutor/Add";
import TutorView from "./components/Tutor/View";

import TabList from "./components/Tabmanagement/List";
import TabEdit from "./components/Tabmanagement/Edit";
import TabAdd from "./components/Tabmanagement/Add";
import TabView from "./components/Tabmanagement/View";

import AdList from "./components/Advertisement/List";
import AdEdit from "./components/Advertisement/Edit";
import AdAdd from "./components/Advertisement/Add";
import AdView from "./components/Advertisement/View";

import AdsCateList from "./components/AdvertisementCategory/List";
import AdsCateEdit from "./components/AdvertisementCategory/Edit";
import AdsCateAdd from "./components/AdvertisementCategory/Add";
// import AdsCateView from "./components/AdvertisementCategory/View";

import SessionList from "./components/Sessionmanagement/List";
import SessionEdit from "./components/Sessionmanagement/Edit";
import SessionAdd from "./components/Sessionmanagement/Add";
import SessionView from "./components/Sessionmanagement/View";

import ClassList from "./components/Class/List";
import ClassEdit from "./components/Class/Edit";
import ClassAdd from "./components/Class/Add";
import ClassView from "./components/Class/View";

import MemList from "./components/Membership/List";
import MemEdit from "./components/Membership/Edit";
import MemAdd from "./components/Membership/Add";
import MemView from "./components/Membership/View";

import MembershipCategoryList from "./components/MembershipCategory/List";
import MembershipCategoryAdd from "./components/MembershipCategory/Add";
import MembershipCategoryEdit from "./components/MembershipCategory/Edit";

import Fontlist from "./components/Fonts/List";
import FontAdd from "./components/Fonts/Add";
import FontEdit from "./components/Fonts/Edit";

import TemplateList from './components/Templates/List';
import TemplateAdd from './components/Templates/Add';
import TemplateEdit from './components/Templates/Edit';

import DashboardList from "./components/Dashboard/List";

import FaqCategoryList from "./components/FaqCategory/List";
import FaqCategoryAdd from "./components/FaqCategory/Add";
import FaqCategoryEdit from "./components/FaqCategory/Edit";

import FaqList from "./components/Faq/List";
import FaqAdd from "./components/Faq/Add";
import FaqEdit from "./components/Faq/Edit";
import FaqImport from "./components/Faq/ImportFaq";

import QuizCategoryList from "./components/QuizCategory/List";
import QuizCategoryAdd from "./components/QuizCategory/Add";
import QuizCategoryEdit from "./components/QuizCategory/Edit";
import QuizCategoryView from "./components/QuizCategory/View";

import QuizList from "./components/Quiz/List";
import QuizAdd from "./components/Quiz/Add";
import QuizEdit from "./components/Quiz/Edit";
import QuizView from "./components/Quiz/View";
import QuizImport from "./components/Quiz/ImportQuiz";

import InstituteList from "./components/Institute/List";
import InstituteAdd from "./components/Institute/Add";
import InstituteEdit from "./components/Institute/Edit";
import InstituteView from "./components/Institute/View";

import AiFeedList from "./components/AiFeed/List";
import AiImport from "./components/AiFeed/Import";


import ProductList from "./components/Product/List";
import ProductAdd from "./components/Product/Add";
import ProductEdit from "./components/Product/Edit";
import ProductView from "./components/Product/View";

import ProductHistoryList from "./components/ProductHistory/List";
import ProductHistoryView from "./components/ProductHistory/View";

import DashboardManagement from "./components/DashboardManagement/List";
import DashboardAdd from "./components/DashboardManagement/Add";
import DashboardEdit from "./components/DashboardManagement/Edit";

import CompanyClone from "./components/Company/CompanyClone";
import './common/css/custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/profile" element={< Adminprofile />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/changepassword" element={<Changepassword />} />
      <Route path="/forget_password" component={Forgetpassword} />
      <Route path="/reset_password/:key" component={Resetpassword} />
      <Route path="/company" element={<Company />} />
      <Route path="/company-add" element={< CompanyAdd />} />
      <Route path="/company-edit/:ID" element={<CompanyEdit/>} />
      <Route path="/font-edit/:ID" element={<FontEdit />} />
      <Route path="/fonts" element={<Fontlist />} />
      <Route path="/font-add" element={<FontAdd />} />

      <Route path="/student" element={<StudentList/>} />
      <Route path="/student-add" element={< StudentAdd/>} />
      <Route path="/student-edit/:ID" element={<StudentEdit/>} />
      <Route path="/student-view/:ID" element={<StudentView/>} />

      <Route path="/blockedstudent" element={<BlockedstudentList/>} />
      <Route path="/blockedstudent-edit/:ID" element={<BlockedstudentEdit/>} />

      <Route path="/tutor" element={<TutorList/>} />
      <Route path="/tutor-add" element={< TutorAdd/>} />
      <Route path="/tutor-edit/:ID" element={<TutorEdit/>} />
      <Route path="/tutor-view/:ID" element={<TutorView/>} />

      <Route path="/tabmanagement" element={<TabList/>} />
      <Route path="/tabmanagement-add" element={< TabAdd/>} />
      <Route path="/tabmanagement-edit/:ID" element={<TabEdit/>} />
      <Route path="/tabmanagement-view/:ID" element={<TabView/>} />

      <Route path="/advertisement" element={<AdList/>} />
      <Route path="/advertisement-add" element={< AdAdd/>} />
      <Route path="/advertisement-edit/:ID" element={<AdEdit/>} />
      <Route path="/advertisement-view/:ID" element={<AdView/>} />

      <Route path="/AdvertisementCategory" element={<AdsCateList/>} />
      <Route path="/AdvertisementCategory-add" element={< AdsCateAdd/>} />
      <Route path="/AdvertisementCategory-edit/:ID" element={<AdsCateEdit/>} />
      {/* <Route path="/AdvertisementCategory-view/:ID" element={<AdsCateView/>} /> */}
      

      <Route path="/sessionmanagement" element={<SessionList/>} />
      <Route path="/sessionmanagement-add" element={< SessionAdd/>} />
      <Route path="/sessionmanagement-edit/:ID" element={<SessionEdit/>} />
      <Route path="/sessionmanagement-view/:ID" element={<SessionView/>} />

      <Route path="/class" element={<ClassList/>} />
      <Route path="/class-add" element={< ClassAdd/>} />
      <Route path="/class-edit/:ID" element={<ClassEdit/>} />
      <Route path="/class-view/:ID" element={<ClassView/>} />

      <Route path="/membership" element={<MemList/>}  />
      <Route path="/membership-edit/:ID" element={<MemEdit/>} />
      <Route path="/membership-view/:ID" element={<MemView/>} />
      <Route path="/membership-add" element={<MemAdd/>} />

      <Route path="/membershipcategory" element={<MembershipCategoryList/>}  />
      <Route path="/membershipcategory-add" element={<MembershipCategoryAdd/>} />
      <Route path="/membershipcategory-edit/:ID" element={<MembershipCategoryEdit/>} />
      
      <Route path="/templates" element={<TemplateList />} />
      <Route path="/template/add" element={<TemplateAdd/>} />
      <Route path="/templates-edit/:ID" element={<TemplateEdit />} />
      <Route path="/dashboard" element={<DashboardList />} /> 
      <Route path="/company-clone/:ID" element={<CompanyClone />} />
      <Route path="/faqcategory" element={<FaqCategoryList/>} />
      <Route path="/faqcategory-add" element={<FaqCategoryAdd/>} />
      <Route path="/faqcategory-edit/:ID" element={<FaqCategoryEdit/>} />  

      <Route path="/quizcategory" element={<QuizCategoryList/>} />
      <Route path="/quizcategory-add" element={<QuizCategoryAdd/>} />
      <Route path="/quizcategory-edit/:ID" element={<QuizCategoryEdit/>} />  
      <Route path="/quizcategory-view/:ID" element={<QuizCategoryView/>} />  

      <Route path="/quiz" element={<QuizList/>} />
      <Route path="/quiz-add" element={<QuizAdd/>} />
      <Route path="/quiz-edit/:ID" element={<QuizEdit/>} />  
      <Route path="/quiz-view/:ID" element={<QuizView/>} /> 
      <Route path="/quiz-import" element={<QuizImport/>} /> 

      <Route path="/institute" element={<InstituteList/>} />
      <Route path="/institute-add" element={<InstituteAdd/>} />
      <Route path="/institute-edit/:ID" element={<InstituteEdit/>} />  
      <Route path="/institute-view/:ID" element={<InstituteView/>} />

      <Route path="/faq" element={<FaqList/>} />
      <Route path="/faq-add" element={<FaqAdd/>} />
      <Route path="/faq-edit/:ID" element={<FaqEdit/>} />
      <Route path="/faq-import" element={<FaqImport/>} />

      <Route path="/aifeed" element={<AiFeedList/>} />
      <Route path="/aifeed-import" element={<AiImport/>} />

      <Route path="/product" element={<ProductList/>} />
      <Route path="/product-edit/:ID" element={<ProductEdit/>} />
      <Route path="/product-view/:ID" element={<ProductView/>} />
      <Route path="/product-add" element={<ProductAdd/>} />
      <Route path="/producthistory" element={<ProductHistoryList/>} />
      <Route path="/producthistory-view/:ID" element={<ProductHistoryView/>} /> 

      <Route path="/dashboardmanagement" element={<DashboardManagement/>} />
      <Route path="/dashboardmanagement/edit/:ID" element={<DashboardEdit/>} />       
      <Route path="/dashboardmanagement-add" element={<DashboardAdd/>} />

    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
