import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import MyEditor from './MyEditor';
const initialState = {
		name:'',
		description:'',
		amount:'',
		image:'',
		image_name: '',
		imagename:'',
		image_preview: '',
		status:'',
		selectedOption:{ value: 'active', label: 'Active'},
		selectedvalue:'',
		short_description:'',
		selectedLinkOption:{value:'product',label:'Product link'},
		selectedLinkvalue:'product',
		selectedProduct:{ value: '', label: 'Select product'},
		selectedProductvalue:'',
		selectedInternal:{ value: '', label: 'Select tab'},
		selectedInternalvalue:'',
		productlist:'',
		button_text:'',
		link:'',
		selectedCateOption: '',
		selectedCatevalue:'',
		categorynamelist:'',
		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
		this.handleInputChange = this.handleInputChange.bind(this);
       this.handleChange = this.handleChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
	   this.onEditorChange1 = this.onEditorChange1.bind( this );
	   this.onChangeHandler = this.onChangeHandler.bind(this);

    }

	onChangeHandler(event){		
		let reader = new FileReader();
		const file = event.target.files[0];
		
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });		  		
			$('.errorimage').html('');
		
		};
		reader.readAsDataURL(file);
		
	}

	

	onEditorChange( evt ) {
		
	   var callfunc = this;
	   setTimeout(function () {
		this.setState( {
            description: evt
        });
		}.bind(this),1000);
	
   }


   onEditorChange1( evt ) {
		
	var callfunc = this;
	setTimeout(function () {
	 this.setState( {
		 short_description: evt
	 } );
	 }.bind(this),1000);
 
}

	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        },()=>{
			const {description,button_text,link} = this.state;
			if(this.state.name){			
				$('.errorname').html('');
			}
			
			if(link){			
				$('.errorlink').html('');
			}
			if(button_text){			
				$('.errorbutton_text').html('');
			}
		});
    }
 

	handleChange = (r,selOption) => {       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'inputtype'){			
			this.setState({selectedLinkOption:selOption})
			this.setState({  selectedLinkvalue : selOption.value});
			$('.errorlinktype').html('');
		}else if(r == 'product'){			
			this.setState({selectedProduct:selOption,selectedInternal:{ value: '', label: 'Select tab'}})
			this.setState({  selectedProductvalue : selOption.value,selectedInternalvalue:''});
			$('.errorproduct').html('');
		}else if(r == 'internal'){			
			this.setState({selectedInternal:selOption,selectedProduct:{ value: '', label: 'Select product'}})
			this.setState({  selectedInternalvalue : selOption.value,selectedProductvalue:''});
			$('.errorproduct').html('');
		}else if(r == 'cate'){			
			this.setState({selectedCateOption:selOption})
			this.setState({  selectedCatevalue : selOption.value});
			$('.errorcate').html('');
		}		
 	};

	componentDidMount() {
		document.title = PageTitle('Add Advertisement');
		if(localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
		}
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
		admin_id: admin_id,
		loggedas: loggedas
		};    
		axios.post(apiUrl+"product/productarraylist",qs.stringify(postobject)).then(res => {
		  if(res.data.status === 'success'){		   
			  this.setState({productlist:res.data.productlist});	
		  }
		}); 
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'advertisement/getcategorynamelist?admin_id='+admin_id)		
		.then(res => {
			if(res.data.status == 'success'){
			
				this.setState({
					categorynamelist: [{value: '', label: 'Select Category'}].concat(res.data.categorynamelist)
				});
			
			}else{
				
			}
		});
    }
		
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status ,cate= '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				if(formPayload.selectedCatevalue === '' && formPayload.selectedCatevalue!== 0){
					cate =formPayload.selectedCateOption.value;
				}else{
					cate = formPayload.selectedCatevalue;
				}
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					name     : formPayload.name,
					description    : formPayload.description,
					amount    : formPayload.amount,
					short_description: formPayload.short_description,
					link: formPayload.link,
					image: formPayload.image,
					linktype: formPayload.selectedLinkvalue,
					module_name: formPayload.selectedInternalvalue,
					button_text: formPayload.button_text,
					status       :  status,
					cate_id:cate	
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		  
				axios.post(apiUrl+"advertisement/add",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							name:'',
							description:'',
							amount:'',
							image:'',
							image_name: '',
							imagename:'',
							image_preview: '',
							status:'',
							selectedOption:{ value: 'active', label: 'Active'},
							selectedvalue:'',
							short_description:'',
							selectedLinkOption:{value:'product',label:'Product link'},
							selectedLinkvalue:'product',
							selectedProduct:{ value: '', label: 'Select product'},
							selectedProductvalue:'',
							selectedInternalvalue:'',
							selectedInternal:{ value: '', label: 'Select tab'},
							productlist:'',
							button_text:'',
							link:''
						   });
						   setTimeout(
						   function() {
							    $('.success_message').html('');
								this.props.navigate('/advertisement');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {name,description,amount,selectedLinkvalue,selectedProductvalue,button_text,link,image_preview,selectedCatevalue,selectedInternalvalue} = this.state;
		let errors = 0;
		if (!name) {			
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){			
			$('.errorname').html('');
		}
		if (!description) {			
			errors++;
			$('.errordesc').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){			
			$('.errordesc').html('');
		}

		if (!selectedLinkvalue) {			
			errors++;
			$('.errorlinktype').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedLinkvalue){			
			$('.errorlinktype').html('');
		}

		if(selectedLinkvalue=='external')
		{
			if (!link) {			
				errors++;
				$('.errorlink').html('<span class="errorspan">Please fill the field</span>');
			}else if(link){			
				$('.errorlink').html('');
			}
		}

		if(selectedLinkvalue=='product')
		{
			if (!selectedProductvalue) {			
				errors++;
				$('.errorproduct').html('<span class="errorspan">Please fill the field</span>');
			}else if(selectedProductvalue){			
				$('.errorproduct').html('');
			}
		}if(selectedLinkvalue=='internal')
		{
			if (!selectedInternalvalue) {			
				errors++;
				$('.errorinternal').html('<span class="errorspan">Please fill the field</span>');
			}else if(selectedInternalvalue){			
				$('.errorinternal').html('');
			}
		}

		
		if (!button_text) {			
			errors++;
			$('.errorbutton_text').html('<span class="errorspan">Please fill the field</span>');
		}else if(button_text){			
			$('.errorbutton_text').html('');
		}
		if (!image_preview) {			
			errors++;
			$('.errorimage').html('<span class="errorspan">Please fill the field</span>');
		}else if(image_preview){			
			$('.errorimage').html('');
		}
		if (!selectedCatevalue) {			
			errors++;
			$('.errorcate').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedCatevalue){			
			$('.errorcate').html('');
		}
		

		

		// if (!amount) {			
		// 	errors++;
		// 	$('.errorshort').html('<span class="errorspan">Please fill the field</span>');
		// }else if(amount){
			
		// 	$('.errorshort').html('');
		// }
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }
	imageRemove = () => {
		this.setState({
		  image_name: '',
		  image_preview: '',
		  image: null
		});
	};
	
  render() {
  	
	if(this.state.description)
	{
		$('.errordesc').html('');
	}
  	const {selectedCateOption,name,amount,selectedOption,selectedLinkOption,selectedProduct,productlist,selectedInternal} = this.state;
	  let loaderimg = '';
	  let preview = '';
	  var current = this;
	  const imagepre = this.state.image_preview;
	  if (this.state.image_preview!=='' && this.state.image_preview!== null && this.state.image_preview!== undefined ) {
	  	preview = <img className="img_class" src={imagepre} alt="" />;
	  }

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="advertisement" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a className="add" href='/advertisement'>Back</a> 
			</div>
			<div className="title">
				<h4>Add Advertisement</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Title<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorname"></div>
				</div>

				<div className="form-group">					
					<label>Category<span class="required" style={{ color: "red" }} > * </span> :</label>
					<Select 
						value={selectedCateOption?selectedCateOption:""}
						options={this.state.categorynamelist} 
						onChange={this.handleChange.bind(this, 'cate')}
					/>
					<div className="errorcate"></div>
	            </div>	

				<div className="form-group">
					<label>Description<span class="required" style={{ color: "red" }} >*</span></label>
						<MyEditor id="description" className="ck-right"  initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>
						{/* <textarea className="form-control" name="description" onChange={this.handleInputChange}  value={this.state.description} id="" rows="5"  autoComplete="off" /> */}
						<div className="errordesc"></div>
				</div>
				<div className="form-group">
					<label>Button text<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
						<div className="errorbutton_text"></div>
				</div>
				
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
					<Select 
						value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
						options={lang.common.status_option} 
						onChange={this.handleChange}
					/>
				</div>		  
			</div>
			<div className="form-right">

				{/* <div className="form-group">
					<label>Amount ($)<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="number" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount} />
						<div className="erroramount"></div>
				</div> */}
				<div className="form-group">
					<label>Link Type<span class="required" style={{ color: "red" }} >*</span></label>
				    {/* <input readOnly type="text" name="module" onChange={this.handleInputChange} className="form-control" value={this.state.module} /> */}
					<Select
						value={selectedLinkOption?selectedLinkOption:""}
							options={lang.common.advertisement_linktype} 
							onChange={this.handleChange.bind(this, 'inputtype')}
							/>
					<div className="errorlinktype"></div>
				</div>

				{selectedLinkOption.value=='external'&&<div className="form-group">
					<label>Link Url<span class="required" style={{ color: "red" }} >*</span></label>
					<input type="text" name="link" onChange={this.handleInputChange} className="form-control" value={this.state.link} />
					<div className="errorlink"></div>
				</div>}

				{selectedLinkOption.value=='product'&&<div className="form-group">					
					<label>Product<span class="required" style={{ color: "red" }} >*</span></label>
					<Select 
						value={selectedProduct?selectedProduct:{ value: '', label: 'Select Product'}}
						options={productlist} 
						// options={lang.common.advertisement_linktype} 
						onChange={this.handleChange.bind(this,'product')}
					/>
					<div className="errorproduct"></div>
				</div>}

				{selectedLinkOption.value=='internal'&&<div className="form-group">					
					<label>Internal<span class="required" style={{ color: "red" }} >*</span></label>
					<Select 
						value={selectedInternal?selectedInternal:{ value: '', label: 'Select tab'}}
						options={lang.common.advertisement_internallinks} 
						// options={lang.common.advertisement_linktype} 
						onChange={this.handleChange.bind(this,'internal')}
					/>
					<div className="errorinternal"></div>
				</div>}


				
				<div className="form-group">
					<label>Short Description</label>
					{/* <div className='ckedit-right-side'>  
					 <MyEditor id="short_description" initialData={this.state.short_description} onEditorChange={this.onEditorChange1}> </MyEditor> 
					 </div>*/}
						<textarea className="form-control" name="short_description" onChange={this.handleInputChange}  value={this.state.short_description} id="" rows="5"  autoComplete="off" />
						<div className="errorshort_desc"></div>
				</div>		
				<div className="form-group">
					<label>Image<span class="required" style={{ color: "red" }} >*</span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{!this.state.image_preview && (
						<div className="form-group">
							{preview}						
						</div>
					)}
					<div className="errorimage"></div>  
					{this.state.image_preview && (
						<div className="image-preview">
						<img src={this.state.image_preview} alt="Preview" className='img_class' />
						<a href="javascript:void(0);" onClick={this.imageRemove.bind(this, this.state.productId)}>X</a>
						</div>
					)}  
				</div> 
				</div>
			</div>
			<div className="btn-group export">				
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);